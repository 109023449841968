.MuiAccordionSummary-content.Mui-expanded {
    margin: 0 0 !important;
    
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px;
    border-radius: 0 15px 15px 0;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0 0 !important;
}



