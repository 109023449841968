@import url(https://fonts.intera.dev/_/plus+jakarta+sans);


body {
  font-family: 'Plus Jakarta Sans' !important;
}

.hr_Listchat {
    width: 50px;
}



.navigation-content {
    display: none !important;
  
  }
  
  .MuiFormLabel-root {
    color: #6E00FF !important;
    font-family: 'Plus Jakarta Sans' !important;
    font-size: 14px !important;
    padding-top: 2px;
    padding-bottom: 2px;
}
  
  .navigation a:hover {
    background-color: #ddd;
    border-radius: 4px;
  }
  
  .navigation:hover .navigation-content {
    display: block;
  }

.MuiButton-containedPrimary {
    color: #fff;
    background-color: #6E00FF;
}

.MuiButton-outlinedPrimary {
  color: #5A6A9D !important;
  border: 0px solid rgba(81, 107, 235, 0.5) !important;
  background-color: white !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  background-color: #EAECEE;
}