.card-detalles-values {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px
}

.card-detalles-values span {
  color: gray; /* Color gris */
  padding-left: 10px;
}
