.login-container {
  display: flex;
  height: 100vh; /* Mantiene la altura completa */
  overflow: hidden; /* Evita el scroll de la página completa */
  position: relative; /* Para posicionar el login sobre el iframe en móviles */
}

.login-form-container {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4;
  position: relative; /* Normal en pantallas grandes */
  z-index: 2; /* Asegura que el formulario esté sobre el iframe en móviles */
}

.iframe-container {
  width: 70%; /* Ocupa el 70% del espacio en pantallas grandes */
  height: 100vh; /* Altura completa de la pantalla */
  position: relative; /* Normal en pantallas grandes */
  overflow: hidden; /* Evita que se desborde */
  z-index: 1; /* Detrás del login en móviles */
}

.dashboard-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.welcome {
  display: flex;
  align-items: center;
  font-size: xx-large;
  justify-content: center;
  margin-bottom: 20px;
}

.submit {
  margin: 20px 0;
}

.view-website-button {
  display: none;
}

/* Estilos responsivos para tablet y móvil */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    height: 100vh; /* Ocupa toda la altura de la pantalla */
    overflow: hidden; /* Evita que se desborde */
  }

  .login-form-container {
    width: 80%; /* Hace el contenedor más pequeño */
    height: auto; /* Ajusta la altura automáticamente */
    padding: 20px;
    position: absolute; /* Flota sobre el iframe */
    top: 20%; /* Ajusta la posición desde arriba */
    left: 50%; /* Centra horizontalmente */
    transform: translateX(-50%); /* Centra completamente */
    background-color: rgba(244, 244, 244, 0.947); /* Fondo semi-transparente */
    border-radius: 8px; /* Añade bordes redondeados */
    z-index: 3; /* Asegura que esté sobre el iframe */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave para destacarlo */
  }

  .iframe-container {
    width: 100%; /* Ocupa todo el ancho en móviles */
    height: 100%; /* Asegura que el iframe ocupe toda la pantalla */
    position: absolute; /* Coloca el iframe en el fondo */
    top: 0;
    left: 0;
    z-index: 1; /* Se mantiene detrás del login */
  }

  .dashboard-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

@media (max-width: 480px) {
  .login-form-container {
    width: 90%; /* Un poco más pequeño en pantallas muy pequeñas */
    top: 25%; /* Ajusta la posición desde arriba */
  }

  .submit {
    width: 100%;
  }
  
  .view-website-button {
    display: block;
  }
}
