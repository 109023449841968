@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.intera.dev/_/plus+jakarta+sans);
/* Google Fonts Import Link */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body{
  overflow: hidden;
}
.active {
  background-color: #1d1b31;
}
.sidebar{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: rgb(110, 0, 255);;
  z-index: 100;
  transition: all 0.5s ease;
}

.sidebar.close{
  width: 78px;
}
.sidebar .logo-details{
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i{
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name{
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name{
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links{
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}
.logo_nametext {
  font-size: small;
  display: flex;
  color: white;
  align-items: flex-start;
  justify-content: center;

}
.sidebar.close .nav-links{
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar{
  display: none;
}
.sidebar .nav-links li{
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li:hover{
  background: #1d1b31;
}
.sidebar .nav-links li .iocn-link{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .iocn-link{
  display: block
}
.sidebar .nav-links li i{
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu i.arrow{
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow{
  display: none;
}
.sidebar .nav-links li a{
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0;
}
.sidebar .nav-links li a .link_name{
  font-size: 18px;
  font-weight: 400;
  color: white;
  transition: all 0.4s ease;

}
.sidebar.close .nav-links li a .link_name{
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu{
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu{
  display: block;
}
.sidebar .nav-links li .sub-menu a{
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover{
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu{
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu{
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name{
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name{
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank{
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank{
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details{
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details{
  background: none;
}
.sidebar.close .profile-details{
  width: 78px;
}
.sidebar .profile-details .profile-content{
  display: flex;
  align-items: center;
}
.sidebar .profile-details img{
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img{
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job{
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job{
  display: none;
}
.sidebar .profile-details .job{
  font-size: 12px;
}
.home-section{
  position: relative;
  background: #E4E9F7;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  padding: 12px;
}
.sidebar.close ~ .home-section{
  left: 78px;
  width: calc(100% - 78px);
}
.home-content{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text{
  color: #11101d;
  font-size: 35px;
}
.home-section .home-content .bx-menu{
  cursor: pointer;
  margin-right: 10px;
}
.home-section .home-content .text{
  font-size: 26px;
  font-weight: 600;
}
.container {
  width: 100%;
}

@media screen and (max-width: 400px){
  .sidebar{
    width: 240px;
  }
  .sidebar.close{
    width: 78px;
  }
  .sidebar .profile-details{
    width: 240px;
  }
  .sidebar.close .profile-details{
    background: none;
  }
  .sidebar.close .profile-details{
    width: 78px;
  }
  .home-section{
    left: 240px;
    width: calc(100% - 240px);
  }
  .sidebar.close ~ .home-section{
    left: 78px;
    width: calc(100% - 78px);
  }
}
.loaderRocket {
  width: 32px;
  height: 90px;
  display: block;
  margin: 20px auto;
  position: relative;
  border-radius: 50% 50% 0 0;
  border-bottom: 10px solid #FF3D00;
  background-color: #FFF;
  background-image: radial-gradient(ellipse at center, #FFF 34%, #FF3D00 35%, #FF3D00 54%, #FFF 55%), linear-gradient(#FF3D00 10px, transparent 0);
  background-size: 28px 28px;
  background-position: center 20px , center 2px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: animloaderBack 1s linear infinite alternate;
}
.loaderRocket::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 64px;
  height: 44px;
  border-radius: 50%;
  box-shadow: 0px 15px #FF3D00 inset;
  top: 67px;
}
.loaderRocket::after {
  content: '';  
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 34px;
  height: 34px;
  top: 112%;
  background: radial-gradient(ellipse at center, #ffdf00 8%, rgba(249, 62, 0, 0.6) 24%, rgba(0, 0, 0, 0) 100%);
  border-radius: 50% 50% 0;
  background-repeat: no-repeat;
  background-position: -44px -44px;
  background-size: 100px 100px;
  box-shadow: 4px 4px 12px 0px rgba(255, 61, 0, 0.5);
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

@keyframes animloaderBack {
  0%, 30%, 70% {
    transform: translateY(0px);
  }
  20%, 40%, 100% {
    transform: translateY(-5px);
  }
}

@keyframes animloader {
  0% {
    box-shadow: 4px 4px 12px 2px rgba(255, 61, 0, 0.75);
    width: 34px;
    height: 34px;
    background-position: -44px -44px;
    background-size: 100px 100px;
  }
  100% {
    box-shadow: 2px 2px 8px 0px rgba(255, 61, 0, 0.5);
    width: 30px;
    height: 28px;
    background-position: -36px -36px;
    background-size: 80px 80px;
  }
}
.MuiAccordionSummary-content.Mui-expanded {
    margin: 0 0 !important;
    
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px;
    border-radius: 0 15px 15px 0;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0 0 !important;
}




.pdf-container {
  
  overflow: hidden;
  cursor: zoom-in; /* Cambia el cursor a puntero al pasar sobre el div */
}

.pdf-container.expanded {
  width: 100%; /* Expandir al 100% de ancho */
  height: auto; /* Ocupar toda la altura visible del viewport */
  cursor: zoom-out; /* Cambia el cursor a zoom-out al pasar sobre el div expandido */
}

.messagesListWrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Estilos para el círculo de carga */
.circleLoading {
  color: #4CAF50;
  position: absolute;
  opacity: 0.7;
  top: 0;
  left: 50%;
  margin-top: 12px;
}

/* Estilos para los mensajes a la izquierda */
.messageLeft,
.messageLeftMedia {
  margin-right: 20px;
  margin-top: 4px;
  min-width: 100px;
  max-width: 600px;
  height: auto;
  display: block;
  position: relative;
  white-space: pre-wrap;
  color: #303030;
  font-family: 'SF Pro Text', 'SF Pro Icons', system, -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', 'Kohinoor Devanagari', sans-serif;
  background-color: #fff4e0;
  align-self: flex-start;
  border-top-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 0;
  box-shadow: 0 1px 1px #b3b3b3;
}
.messageLeft:hover {
  /* Estilos para el hover */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Sombra más pronunciada */
}

.messageLeft:hover #messageActionsButton {
  /* Estilos para mostrar el botón de acciones al hacer hover */
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

/* Estilos para el botón de descarga de medios */
.downloadMedia {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  padding: 10px;
}

.loader {
  
  width: 164px;
  height: 164px;
  position: absolute;
  opacity: 0.9;
  top: 60px;
  left: 40%;
  margin-top: 12px;
}
.loader::before , .loader::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #8922dc;
  left: 50%;
  top: 50%;
  animation: rotate 1s ease-in infinite;
}
.loader::after {
width: 20px;
height: 20px;
background-color: #e0b107;
animation: rotate 1s ease-in infinite, moveY 1s ease-in infinite ;
}

@keyframes moveY {
0% , 100% {top: 10%}
45% , 55% {top: 59%}
60% {top: 40%}
}
@keyframes rotate {
0% { transform: translate(-50%, -100%) rotate(0deg) scale(1 , 1)}
25%{ transform: translate(-50%, 0%) rotate(180deg) scale(1 , 1)}
45% , 55%{ transform: translate(-50%, 100%) rotate(180deg) scale(3 , 0.5)}
60%{ transform: translate(-50%, 100%) rotate(180deg) scale(1, 1)}
75%{ transform: translate(-50%, 0%) rotate(270deg) scale(1 , 1)}
100%{ transform: translate(-50%, -100%) rotate(360deg) scale(1 , 1)}
}
  
.MuiCardMedia-img {
    object-fit: cover;
    height: 300px;
  }
.login-container {
  display: flex;
  height: 100vh; /* Mantiene la altura completa */
  overflow: hidden; /* Evita el scroll de la página completa */
  position: relative; /* Para posicionar el login sobre el iframe en móviles */
}

.login-form-container {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4;
  position: relative; /* Normal en pantallas grandes */
  z-index: 2; /* Asegura que el formulario esté sobre el iframe en móviles */
}

.iframe-container {
  width: 70%; /* Ocupa el 70% del espacio en pantallas grandes */
  height: 100vh; /* Altura completa de la pantalla */
  position: relative; /* Normal en pantallas grandes */
  overflow: hidden; /* Evita que se desborde */
  z-index: 1; /* Detrás del login en móviles */
}

.dashboard-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.welcome {
  display: flex;
  align-items: center;
  font-size: xx-large;
  justify-content: center;
  margin-bottom: 20px;
}

.submit {
  margin: 20px 0;
}

.view-website-button {
  display: none;
}

/* Estilos responsivos para tablet y móvil */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    height: 100vh; /* Ocupa toda la altura de la pantalla */
    overflow: hidden; /* Evita que se desborde */
  }

  .login-form-container {
    width: 80%; /* Hace el contenedor más pequeño */
    height: auto; /* Ajusta la altura automáticamente */
    padding: 20px;
    position: absolute; /* Flota sobre el iframe */
    top: 20%; /* Ajusta la posición desde arriba */
    left: 50%; /* Centra horizontalmente */
    transform: translateX(-50%); /* Centra completamente */
    background-color: rgba(244, 244, 244, 0.947); /* Fondo semi-transparente */
    border-radius: 8px; /* Añade bordes redondeados */
    z-index: 3; /* Asegura que esté sobre el iframe */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave para destacarlo */
  }

  .iframe-container {
    width: 100%; /* Ocupa todo el ancho en móviles */
    height: 100%; /* Asegura que el iframe ocupe toda la pantalla */
    position: absolute; /* Coloca el iframe en el fondo */
    top: 0;
    left: 0;
    z-index: 1; /* Se mantiene detrás del login */
  }

  .dashboard-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

@media (max-width: 480px) {
  .login-form-container {
    width: 90%; /* Un poco más pequeño en pantallas muy pequeñas */
    top: 25%; /* Ajusta la posición desde arriba */
  }

  .submit {
    width: 100%;
  }
  
  .view-website-button {
    display: block;
  }
}

.card-detalles-values {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px
}

.card-detalles-values span {
  color: gray; /* Color gris */
  padding-left: 10px;
}

body {
  font-family: 'Plus Jakarta Sans' !important;
}

.hr_Listchat {
    width: 50px;
}



.navigation-content {
    display: none !important;
  
  }
  
  .MuiFormLabel-root {
    color: #6E00FF !important;
    font-family: 'Plus Jakarta Sans' !important;
    font-size: 14px !important;
    padding-top: 2px;
    padding-bottom: 2px;
}
  
  .navigation a:hover {
    background-color: #ddd;
    border-radius: 4px;
  }
  
  .navigation:hover .navigation-content {
    display: block;
  }

.MuiButton-containedPrimary {
    color: #fff;
    background-color: #6E00FF;
}

.MuiButton-outlinedPrimary {
  color: #5A6A9D !important;
  border: 0px solid rgba(81, 107, 235, 0.5) !important;
  background-color: white !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  background-color: #EAECEE;
}
