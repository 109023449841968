.pdf-container {
  
  overflow: hidden;
  cursor: zoom-in; /* Cambia el cursor a puntero al pasar sobre el div */
}

.pdf-container.expanded {
  width: 100%; /* Expandir al 100% de ancho */
  height: auto; /* Ocupar toda la altura visible del viewport */
  cursor: zoom-out; /* Cambia el cursor a zoom-out al pasar sobre el div expandido */
}

.messagesListWrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Estilos para el círculo de carga */
.circleLoading {
  color: #4CAF50;
  position: absolute;
  opacity: 0.7;
  top: 0;
  left: 50%;
  margin-top: 12px;
}

/* Estilos para los mensajes a la izquierda */
.messageLeft,
.messageLeftMedia {
  margin-right: 20px;
  margin-top: 4px;
  min-width: 100px;
  max-width: 600px;
  height: auto;
  display: block;
  position: relative;
  white-space: pre-wrap;
  color: #303030;
  font-family: 'SF Pro Text', 'SF Pro Icons', system, -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', 'Kohinoor Devanagari', sans-serif;
  background-color: #fff4e0;
  align-self: flex-start;
  border-top-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 0;
  box-shadow: 0 1px 1px #b3b3b3;
}
.messageLeft:hover {
  /* Estilos para el hover */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Sombra más pronunciada */
}

.messageLeft:hover #messageActionsButton {
  /* Estilos para mostrar el botón de acciones al hacer hover */
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

/* Estilos para el botón de descarga de medios */
.downloadMedia {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  padding: 10px;
}

.loader {
  
  width: 164px;
  height: 164px;
  position: absolute;
  opacity: 0.9;
  top: 60px;
  left: 40%;
  margin-top: 12px;
}
.loader::before , .loader::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #8922dc;
  left: 50%;
  top: 50%;
  animation: rotate 1s ease-in infinite;
}
.loader::after {
width: 20px;
height: 20px;
background-color: #e0b107;
animation: rotate 1s ease-in infinite, moveY 1s ease-in infinite ;
}

@keyframes moveY {
0% , 100% {top: 10%}
45% , 55% {top: 59%}
60% {top: 40%}
}
@keyframes rotate {
0% { transform: translate(-50%, -100%) rotate(0deg) scale(1 , 1)}
25%{ transform: translate(-50%, 0%) rotate(180deg) scale(1 , 1)}
45% , 55%{ transform: translate(-50%, 100%) rotate(180deg) scale(3 , 0.5)}
60%{ transform: translate(-50%, 100%) rotate(180deg) scale(1, 1)}
75%{ transform: translate(-50%, 0%) rotate(270deg) scale(1 , 1)}
100%{ transform: translate(-50%, -100%) rotate(360deg) scale(1 , 1)}
}
  